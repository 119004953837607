import React, { useEffect, useState } from 'react'
import queryString from 'query-string'

import vediAPI, { getRepresentadosPorApp, getTokenPublico } from '../../utils/vedi-api/vediAPI'
import Loading from '../UI/Loading/Loading'

import classes from './ProcesarLoginCidi.module.css'
import { useHistory } from 'react-router-dom'

const ProcesarLoginCidi = (props) => {
    const { location } = props
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false);
    const history = useHistory();

    useEffect(() => {
        const { cidi } = queryString.parse(location.search)

        const loginCIDI = (_token) => vediAPI.get('/v1/Usuario/LoginCiDi', {
            headers: {
                '--token': _token,
                '--Codigo': cidi
            }
        })
            .then(({ data }) => {
                if (!data.ok) {
                    throw new Error(data.error || 'Error procesando la solicitud')
                }
                setLoading(false)

                const idAplicacion = localStorage.getItem('idAplicacion');
                const {token, refreshToken} = data.return;
                if (idAplicacion) {
                    redirectToApp(idAplicacion, token)
                } else {
                    document.cookie = `tokenVeDi=${token}; path=/`;
                    document.cookie = `refreshTokenVeDi=${refreshToken}; path=/`;
                    window.open(process.env.REACT_APP_URL_PORTAL, '_self')
                }
            });

        getTokenPublico()
            .then(token => loginCIDI(token))
            .catch(error => {
                console.log('error en inicio de sesion: ', error)
                setLoading(false);
                setError(true);
            });

        return () => {
            if (localStorage.getItem('idAplicacion')) {
                localStorage.removeItem('idAplicacion');
            }
        }
    }, [location.search])

    const redirectToApp = (idAplicacion, token) => {
        localStorage.removeItem('idAplicacion');
        getRepresentadosPorApp(idAplicacion, token)
            .then(app => {
                if (app) {
                    window.open(app.urlBase, '_self');
                }
            })
    }

    if(loading){
        return <Loading show={loading} /> 
    }

    return (
        <>
        {
            error 
            ? (
                <div className={classes.errorRoot}>
                    <p className={classes.errorText}>Ocurrió un error al iniciar sesión. Intente nuevamente en unos minutos.</p>
                    <span className={classes.linkVolver} onClick={history.goBack}>Volver</span>
                </div>
            )
            : null
        }
        </>
    )
}

export default ProcesarLoginCidi