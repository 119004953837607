import React, { useState, useEffect } from 'react'

import VeDilogo from '../../../../assets/images/VeDilogo.png'
import VeDilogoMobile from '../../../../assets/images/VeDilogoMobile.png'
import { ReactComponent as MenuIcon } from '../../../../assets/icons/menu.svg'
import classes from './Toolbar.module.css'
import { redirectToCiDi } from '../../../../utils/generic-functions/genericFunctions'

const Toolbar = (props) => {
    const { menuIconClicked } = props
    const [shadow, setShadow] = useState([classes.fixed])

    useEffect(() => {
        // const finDeHeaderElement = document.getElementById('finDeHeader');
        const startHeaderElement = document.getElementById('startHeader')
        window.addEventListener('scroll', () => {
            // const finDeHeaderPosition = finDeHeaderElement.getBoundingClientRect().top
            const startHeaderPosition = startHeaderElement.getBoundingClientRect().top
            // if (0 > finDeHeaderPosition - 60) {
            if (0 > startHeaderPosition ) {
                setShadow([classes.fixed, classes.shadow])
            } else {
                setShadow([classes.fixed])
            }
        });
    }, []);

    const loginHandler = () => {
        redirectToCiDi()
    }

    // const registrateHandler = () => {
    //     registrate()
    // }

    return (
        <div className={shadow.join(' ')} >
            <div className={classes.container}>
                <img className={classes.logo} src={VeDilogo} alt="vedi-logo" />
                <div className={classes.rightLinks}>
                    <a className={classes.link} href="#que-es">¿Qué es VeDi?</a>
                    <a className={classes.link} href="#como-accedo">¿Cómo accedo?</a>
                    <a className={classes.link} href="#accesibilidad">Accesibilidad</a>
                    {/* <div 
                        className={classes.link}
                        onClick={registrateHandler}> Registrate</div> */}
                    <button
                        className={[classes.button, classes.link].join(' ')}
                        onClick={loginHandler}>Ingresar a VeDi</button>
                </div>
            </div>

            <div className={classes.containerMobile}>
                <MenuIcon
                    className={classes.icon}
                    onClick={menuIconClicked} />
                <img src={VeDilogoMobile} alt="vedi-logo-mobile" />
                <div className={classes.rightLinks}>
                    <button
                        className={[classes.link, classes.button].join(' ')}
                        onClick={loginHandler}>Ingresar</button>
                </div>
            </div>
        </div>
    )
}

export default Toolbar