import React from 'react'

import classes from './LeftMenu.module.css'
import Backdrop from '../../UI/Backdrop/Backdrop'
import { redirectToCiDi, registrate } from '../../../utils/generic-functions/genericFunctions'

const LeftMenu = (props) => {
    const { isMenuVisible, closeMenu } = props

    const menuStyle = { width: 0 }
    if (isMenuVisible) {
        menuStyle.width = '75%'
    }

    const navigateHandler = (to) => {
        closeMenu()
        //logica para navegacion 
     }

     const loginHandler = () => {
        redirectToCiDi()
    }

    // const registrateHandler = () => {
    //     registrate()
    // }

    return (
        <React.Fragment>
            <Backdrop
                show={isMenuVisible}
                clicked={closeMenu} />
            <div className={classes.menu}
                style={menuStyle}>
                <div className={classes.menuItems}>
                    <div 
                        className={classes.login}
                        onClick={loginHandler}>
                        <p>Ingresar a VeDi</p>
                    </div>
                    <ul>
                        <li onClick={() => navigateHandler()}><a href="#que-es">¿Qué es VeDi?</a></li>
                        <li onClick={() => navigateHandler()}><a href="#como-accedo">¿Cómo accedo?</a></li>
                        <li onClick={() => navigateHandler()}><a href="#accesibilidad">Accesibilidad</a></li>
                        {/* <li onClick={registrateHandler}><a href="">Registrate</a></li> */}
                    </ul>
                </div>
            </div>
        </React.Fragment>
    )
}

export default LeftMenu