import React, { useEffect, useState } from "react";

import Toolbar from "./Toolbar/Toolbar";
import classes from "./Header.module.css";
import {
  getAplicaciones,
  getTokenPublico,
} from "../../../utils/vedi-api/vediAPI";
import HeaderImg from "../../../assets/images/header/header.png";
import { redirectToCiDi } from "../../../utils/generic-functions/genericFunctions";
import Headerimg from "../../../assets/images/header/header.png";
const Header = (props) => {
  const { menuIconClicked } = props;
  const [vinculos, setVinculos] = useState([]);

  useEffect(() => {
    getTokenPublico()
      //get aplicaciones llama a los botones de los servicios comentado mas abajo
      //.then(getAplicaciones)
      .then(setVinculos)
      .catch((err) => console.log("ERROR", err));
  }, []);

  const onVinculoClicked = ({ url }) => {
    window.open(url, "_blank");
  };

  const loginHandler = () => {
    redirectToCiDi();
  };

  return (
    <header className={classes.header} id="startHeader">
      <Toolbar menuIconClicked={menuIconClicked} />
      <img
        src={Headerimg}
        className={classes.imagenPrincipal}
        alt="Imagen principal de cabecera"
      />
      <div className={classes.headerContainer}>
        <h1>La municipalidad en tu casa</h1>
        <p>
          Más cerca de los vecinos con una plataforma para acceder a los
          servicios digitalmente
        </p>
        {/* <div className={classes.vinculosRoot}>{console.log(vinculos,'vinculos')}
                    {
                        vinculos
                            .filter((v) => [1, 2, 8, 9].indexOf(v.id) !== -1)
                            .map((v) => (
                                <button
                                    key={v.id}
                                    className={classes.vinculo}
                                    onClick={() => onVinculoClicked(v)}>{v.nombre}</button>
                            ))
                    }
                </div> */}
        {/* <a onClick={loginHandler}>Ingresá para ver más servicios</a> */}
      </div>
      <div id="finDeHeader" style={{ height: 1 }}></div>
    </header>
  );
};

export default Header;
