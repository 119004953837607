import React, { useState, useEffect } from 'react'

import Header from './Header/Header'
import MainContent from './MainContent/MainContent'
import Footer from './Footer/Footer'
import LeftMenu from './LeftMenu/LeftMenu'
import { redirectToCiDi, useQuery } from '../../utils/generic-functions/genericFunctions'

const LandingPage = (props) => {
    const [isMenuVisible, setIsMenuVisible] = useState(false)
    const [index, setIndex] = useState(0)
    const query = useQuery()

    useEffect(() => {
        const idAplicacion = query.get("idAplicacion")
        if (idAplicacion) {
            localStorage.setItem('idAplicacion', idAplicacion)
            redirectToCiDi()
        }
    }, [])

    //navegacion con los botones re.pag y av.pag
    useEffect(() => {
        const callback = event => {
            switch (event.keyCode) {
                case 33:
                    setIndex(prevIndex => {
                        if (prevIndex > 0) {
                            return prevIndex - 1
                        }
                        return 0
                    })
                    break;
                case 34:
                    setIndex(prevIndex => (prevIndex + 1) % 5)
                    break;
                default:
            }
        }

        window.addEventListener("keydown", callback);

        return () => window.removeEventListener("keydown", callback)
    }, [])

    useEffect(() => {
        switch (index) {
            case 1:
                window.location.hash = '#que-es'
                break;
            case 2:
                window.location.hash = '#como-accedo'
                break;
            case 3:
                window.location.hash = '#accesibilidad'
                break;
            case 4:
                window.location.hash = '#footer'
                break;
            default:
                window.location.hash = ''
        }
    }, [index])

    const menuIconClickedHandler = () => {
        setIsMenuVisible(isVisible => !isVisible)
    }

    return (
        <React.Fragment>
            <LeftMenu
                isMenuVisible={isMenuVisible}
                closeMenu={() => setIsMenuVisible(false)} />
            <Header menuIconClicked={menuIconClickedHandler} />
            <MainContent />
            <Footer />
        </React.Fragment>
    )
}

export default LandingPage